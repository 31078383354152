import { useQuery, UseQueryOptions } from "react-query";
import { ITeam, Route } from "@edgetier/watchtower-types";
import { AxiosError } from "axios";
import { axios } from "utilities/axios";

/**
 * Request teams' details from the backend.
 * @param tagId The ID of the tag.
 */
const requestTeam = async (url: string) => {
    const { data } = await axios.get<ITeam>(url);
    return data;
};
/**
 * A query to request for team details from backend
 * @param teamId The ID of the team.
 */
export const useTeam = <T = ITeam>(
    teamId: string,
    options: Omit<UseQueryOptions<ITeam, AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Route.Team.replace(":teamId", teamId);
    return useQuery<ITeam, AxiosError, T>([url, teamId], () => requestTeam(url), options);
};
