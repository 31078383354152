import toast from "react-hot-toast";
import { getServerError } from "./get-server-error";

/**
 * Show a server error as a toast message.
 * @param serverError Backend error.
 */
export const showServerError = (serverError: any) => {
    toast.error(getServerError(serverError));
};
