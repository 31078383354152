import { ITag, Route } from "@edgetier/watchtower-types";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { axios } from "utilities/axios";

/**
 * Request an tag's details from the backend.
 * @param tagId The ID of the tag.
 */
export const requestTag = async (tagId: string) => {
    const { data } = await axios.get<ITag>(Route.Tag.replace(":tagId", tagId));
    return data;
};

/**
 * A query to request an tag's details from the backend.
 * @param tagId The ID of the tag.
 */
export const useTag = <T = ITag>(tagId: string, configuration: UseQueryOptions<ITag, AxiosError, T> = {}) => {
    return useQuery<ITag, AxiosError, T>([Route.Tag, tagId], () => requestTag(tagId), configuration);
};
