import { useTeam } from "hooks-for/teams/use-team";

/**
 * Team info specific breadcrumb item.
 * @param props.teamId The team id.
 * @returns Null if the team is loading. Otherwise, the team name
 */
export const TeamInfoBreadcrumb = ({ teamId }: { teamId: string }) => {
    const { data: team, isLoading } = useTeam(teamId);
    return isLoading ? null : <span>{team?.teamName}</span>;
};
