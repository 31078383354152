import { FunctionComponent, memo } from "react";
import { IProps } from "./redirect-to-reviews.types";
import "./redirect-to-reviews.scss";
import { getDefaultRouteUrls } from "utilities/get-default-route-urls";
import { PageNames } from "types-for/routes";
import { Navigate } from "react-router-dom";

/**
 * Component for redirecting to the reviews page.
 *
 * @returns {JSX.Element} RedirectToReviews component.
 */
const RedirectToReviews: FunctionComponent<IProps> = () => {
    return <Navigate to={getDefaultRouteUrls()[PageNames.Reviews].url} replace />;
};

export default memo(RedirectToReviews);
