import { Permission } from "@edgetier/watchtower-types";
import { IPermission } from "@edgetier/types";

/**
 *
 * @param usersPermissions The permissions that the user has.
 * @returns True if the user can manage phrase triggers. If the user has permission to manage interaction tags, return
 * true or false based on the EDIT_INTERACTION_TAGS_ENABLED feature flag.
 */
export const checkPermissionsAndFlag = (usersPermissions: IPermission[]) => {
    if (usersPermissions.some(({ permissionId }) => permissionId === Permission.ManagePhraseTriggers)) {
        return true;
    }
    return (
        usersPermissions.some(({ permissionId }) => permissionId === Permission.ManageInteractionTags) ||
        usersPermissions.length > 0
    );
};
