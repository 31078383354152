import { FunctionComponent, memo } from "react";
import { IProps } from "./redirect-to-explore.types";
import { Navigate } from "react-router-dom";
import { getDefaultRouteUrls } from "utilities/get-default-route-urls";
import { PageNames } from "types-for/routes";
import "./redirect-to-explore.scss";

/**
 * Redirect to the explore screen. This is done in a component and not directly in routes/index.tsx because that file
 * needs to run before we can call getDefaultRouteUrls.
 */
const RedirectToExplore: FunctionComponent<IProps> = () => {
    return <Navigate to={getDefaultRouteUrls()[PageNames.Explore].url} replace />;
};

export default memo(RedirectToExplore);
