import { useQuery, UseQueryOptions } from "react-query";
import { IPhraseTrigger, Route } from "@edgetier/watchtower-types";
import { AxiosError } from "axios";
import { axios } from "utilities/axios";

/**
 * Request the phrase trigger from the backend.
 * @param url  The URL to get the phrase trigger.
 * @returns Phrase trigger details
 */
const requestPhraseTrigger = async (url: string) => {
    const { data } = await axios.get(url);
    return data;
};

/**
 * A query hook to retrieve a phrase trigger using an ID.
 * @param phraseTriggerId
 * @param configuration     Options and configuration passed to the query.
 */
const usePhraseTrigger = <T = IPhraseTrigger>(
    phraseTriggerId: string,
    configuration: Omit<UseQueryOptions<IPhraseTrigger, AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Route.PhraseTrigger.replace(":phraseTriggerId", phraseTriggerId);
    return useQuery<IPhraseTrigger, AxiosError, T>([url], () => requestPhraseTrigger(url), configuration);
};

export default usePhraseTrigger;
