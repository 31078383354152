import { IClient } from "@edgetier/watchtower-types";
import { useFlags } from "hooks-for/feature-flags";
import { useCallback } from "react";
import { FEATURE_FLAGS } from "types-for/feature-flags";
import { IRouteHandleEnabledValue } from "types-for/routes";

/**
 * Custom hook for filtering navigation children based on feature flags and client settings.
 * @returns {function} Filter function to determine visibility of navigation items.
 */
const useIsRouteEnabled = () => {
    const allFlags = useFlags(Object.values(FEATURE_FLAGS));
    const filter = useCallback(
        (rawEnabledConfiguration: IRouteHandleEnabledValue, client: IClient | undefined) => {
            const enabledConfigurationArray = Array.isArray(rawEnabledConfiguration)
                ? rawEnabledConfiguration
                : [rawEnabledConfiguration];

            return enabledConfigurationArray.every((enabledConfiguration) => {
                if ("clientKey" in enabledConfiguration) {
                    return enabledConfiguration.validation(client?.[enabledConfiguration.clientKey]) === true;
                }
                if ("featureFlag" in enabledConfiguration.validation) {
                    const validatingFlags = enabledConfiguration.validation.featureFlag;
                    return Array.isArray(validatingFlags)
                        ? validatingFlags.every((flag) => allFlags[flag].enabled)
                        : allFlags[validatingFlags].enabled;
                }
                return enabledConfiguration.validation();
            });
        },
        [allFlags]
    );

    return filter;
};

export default useIsRouteEnabled;
