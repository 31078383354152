import { NoMatch, Page, PageContent, PageHeader } from "@edgetier/client-components";
import { Spinner } from "@edgetier/components";
import VerifyPermission from "components-for/pages/verify-permission";
import VerifyManageTagsPermission from "components-for/tags/verify-manage-tags-permission";
import RoutesHierarchy from "constants/routes";
import { useClient } from "hooks-for/clients/use-client";
import useIsRouteEnabled from "hooks-for/navigation/use-is-route-enabled/use-is-route-enabled";
import { useUser } from "hooks-for/users/use-user";
import { PropsWithChildren, lazy as ReactLazy, ReactNode, Suspense, useMemo, useState } from "react";
import { RouteObject } from "react-router-dom";
import { IRouteHandleEnabledValue, IRouteHierarchy, isIRouteHandleSimplePermission } from "types-for/routes";
import { getAccessToken } from "utilities/access-token";

/**
 * Component for checking if a route is enabled based on user access and client settings.
 * @param {object} props - The props for the IsRouteEnabled component.
 * @param {boolean} props.enabled - The route enabled value.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} IsRouteEnabled component.
 */
const IsRouteEnabled = ({
    enabled,
    children,
}: PropsWithChildren<{ enabled: IRouteHandleEnabledValue }>): JSX.Element => {
    const isRouteEnabled = useIsRouteEnabled();
    const accessToken = useMemo(() => getAccessToken(), []);
    const [hasAccessToken] = useState(typeof accessToken === "string");
    const { data: user } = useUser({
        enabled: hasAccessToken,
    });
    const { data: client } = useClient(user?.clientId!, {
        enabled: typeof user !== "undefined",
    });

    return <>{isRouteEnabled(enabled, client) ? children : <NoMatch />}</>;
};

/**
 * Internally used function to get the router setup. It gets a routes hierarchy and returns a router setup that works with createBrowserRouter.
 * @param hierarchy The routes hierarchy to get the router setup from. Defaults to the RoutesHierarchy constant.
 * @returns The router setup.
 */
export const getRouterSetup = (hierarchy: IRouteHierarchy = RoutesHierarchy): RouteObject[] => {
    return Object.keys(hierarchy).map<RouteObject>((key) => {
        // First we prepare the route object props we need.
        const { children, ...otherRouteProps } = hierarchy[key];

        // First we check if route can be enabled/disabled
        const enabledConfiguration = hierarchy[key].handle?.enabled;
        // Second we check if there's a "permission" property in the route handle section.
        const routePermission = hierarchy[key].handle?.permission;

        // We don't need to check if the permission has the proper type as it is forced in the RouteHierarchy type.
        if (routePermission || enabledConfiguration) {
            // We extract the lazy prop because we don't want to include it in the route
            const { lazy, ...otherPrivateRouteProps } = otherRouteProps;

            /**
             * Doesn't makes any sense to have a route with a permission defined and an undefined lazy function.
             * So we throw an error to warn the developer.
             */
            if (!lazy) throw new Error(`Route ${key} has a permission but no lazy function.`);

            /**
             * React router lazy functions force the component to be exported with the name "Component".
             * The react native lazy function forces the component to be exported with the name "default".
             * So here we wrap the react router lazy function to return a default.
             */
            const adaptedLazyFunction = () => lazy()?.then((module) => ({ default: module.Component! }));

            // And we create the React.lazy component.
            const LazyComponent = ReactLazy(adaptedLazyFunction);
            const InnerComponent = () => (
                <Suspense
                    fallback={
                        <Page>
                            <PageHeader title="Loading..." />
                            <PageContent>
                                <Spinner />
                            </PageContent>
                        </Page>
                    }
                >
                    <LazyComponent />
                </Suspense>
            );

            const WrapperComponent: ReactNode = (
                <ConditionalWrapper
                    condition={!!enabledConfiguration}
                    wrapper={(children) => <IsRouteEnabled enabled={enabledConfiguration!}>{children}</IsRouteEnabled>}
                >
                    <ConditionalWrapper
                        condition={!!routePermission}
                        wrapper={
                            isIRouteHandleSimplePermission(routePermission!)
                                ? (children) => (
                                      <VerifyPermission
                                          name={`conditional router wrapper ${key}`}
                                          permission={routePermission}
                                          isRoute
                                      >
                                          {children}
                                      </VerifyPermission>
                                  )
                                : (children) => (
                                      <VerifyManageTagsPermission name={`conditional router wrapper ${key}`} isRoute>
                                          {children}
                                      </VerifyManageTagsPermission>
                                  )
                        }
                    >
                        <InnerComponent />
                    </ConditionalWrapper>
                </ConditionalWrapper>
            );

            // As we want to verify the permission, we wrap the lazy component inside a VerifyPermission component.
            return {
                id: key,
                element: WrapperComponent,
                ...(hierarchy[key].children ? { children: getRouterSetup(hierarchy[key].children) } : {}),
                ...otherPrivateRouteProps,
            } as RouteObject;
        } else {
            // If the component doesn't have a permission defined, we just return it.
            return {
                id: key,
                ...(hierarchy[key].children ? { children: getRouterSetup(hierarchy[key].children) } : {}),
                ...otherRouteProps,
            } as RouteObject;
        }
    });
};

const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
}: PropsWithChildren<{
    condition: boolean;
    wrapper: (children: ReactNode | undefined) => JSX.Element;
}>): JSX.Element => (condition ? wrapper(children) : <>{children}</>);
