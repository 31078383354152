import { AxiosError } from "axios";

export const DEFAULT_ERROR = "An unknown error occurred.";

/**
 * Get the error description from the backend. The error format isn't guaranteed which is why this is very cautious.
 * @param serverError Backend error response.
 * @returns           Server error message.
 */
export const getServerError = (serverError: AxiosError<{ description: string }> | any) => {
    try {
        return serverError.response?.data?.description ?? DEFAULT_ERROR;
    } catch {
        return DEFAULT_ERROR;
    }
};
