import { FunctionComponent, memo } from "react";
import { IProps } from "./verify-manage-tags-permission.types";
import VerifyPermission from "components-for/pages/verify-permission";
import { Permission } from "@edgetier/watchtower-types";
import { checkPermissionsAndFlag } from "./verify-manage-tags-permission.utilities";
import "./verify-manage-tags-permission.scss";

/**
 * Verify if the user can manage tags based on the permissions they have and feature flags.
 * @param props.children              The component that will be conditionally rendered.
 * @param props.verifyPermissionProps Props that can be applied to VerifyPermission.
 */
const VerifyManageTagsPermission: FunctionComponent<IProps> = ({ name, children, ...verifyPermissionProps }) => (
    <VerifyPermission
        finalCheck={checkPermissionsAndFlag}
        permission={[Permission.ManagePhraseTriggers, Permission.ManageInteractionTags]}
        {...verifyPermissionProps}
    >
        {children}
    </VerifyPermission>
);

export default memo(VerifyManageTagsPermission);
