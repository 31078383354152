import { FunctionComponent, memo } from "react";
import { IProps } from "./redirect-to-index.types";
import "./redirect-to-index.scss";
import { useUserHasAgentAccess } from "hooks-for/users/use-user-has-agent-access";
import { RedirectToExplore } from "../redirect-to-explore";
import { RedirectToReviews } from "../redirect-to-reviews";

/**
 * Component for redirecting to the index page.
 *
 * @returns {JSX.Element} RedirectToIndex component.
 */
const RedirectToIndex: FunctionComponent<IProps> = () => {
    const isAgentAccess = useUserHasAgentAccess();

    if (isAgentAccess) {
        return <RedirectToReviews />;
    }
    return <RedirectToExplore />;
};

export default memo(RedirectToIndex);
