let clientId: string | null = null;

/**
 * Retrieve the in-memory access token.
 * @returns The access token.
 */
export const getClientId = () => clientId;

/**
 * Store the access token.
 * @param newAccessToken Access token.
 */
export const setClientId = (newClientId: string) => {
    clientId = newClientId;
};

/**
 * Retrieve the in-memory access token.
 * @returns The access token.
 */
export const deleteClientId = () => {
    clientId = null;
};
