import { useRolePermissions } from "@edgetier/roles-permissions";
import { Permission, Route } from "@edgetier/watchtower-types";
import { useUser } from "hooks-for/users/use-user";
import { axios } from "utilities/axios";

/**
 * Establish if the current user has permission for a page or element
 * @param permission The permission(s) to check. If multiple are passed, the permissions are OR-ed.
 */
const usePermission = (permission: Permission | Permission[]) => {
    const permissions = Array.isArray(permission) ? permission : [permission];

    const { data: user } = useUser();

    const roleId = user?.roleId;
    const { data: rolePermissions, ...otherProperties } = useRolePermissions(Route.RolePermissions, axios, roleId!, {
        enabled: typeof roleId === "string",
    });

    // The subset of the permissions passed that the user has.
    const userPermissions = (rolePermissions ?? []).filter(({ permissionId }) => permissions.includes(permissionId));

    return {
        hasPermission: userPermissions.length > 0,
        permissions: userPermissions,
        ...otherProperties,
    };
};

export default usePermission;
