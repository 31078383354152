import usePhraseTrigger from "hooks-for/phrase-triggers/use-phrase-trigger";

/**
 * Phrase Trigger specific breadcrumb item.
 * @param props.phraseTriggerId The phrase trigger id.
 * @returns Null if the phrase trigger is loading. Otherwise, if phraseTriggerId is defined, then Trigger performance string, else, Create Phrase Trigger string.
 */
export const PhraseTriggerInfoBreadcrumb = ({ phraseTriggerId }: { phraseTriggerId: string }) => {
    const { data: phraseTrigger, isLoading } = usePhraseTrigger(phraseTriggerId, { enabled: !!phraseTriggerId });
    if (isLoading) {
        return null;
    }
    if (phraseTriggerId) {
        return <span>{phraseTrigger?.phrase}</span>;
    } else {
        return <span>{"Create Phrase Trigger"}</span>;
    }
};
