import { useClient } from "hooks-for/clients/use-client";
import { useUser } from "./use-user";
import { useMemo } from "react";

/**
 * Checks if the user has agent access role.
 *
 * @returns {boolean} - Returns true if the user has agent access, otherwise false.
 */
export const useUserHasAgentAccess = () => {
    const user = useUser();
    const client = useClient(user?.data?.clientId!);

    return useMemo(() => {
        return user?.data?.roleId === client.data?.agentAccessRoleId;
    }, [client, user]);
};
