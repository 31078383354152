import { useQuery, UseQueryOptions } from "react-query";
import { IClient, Route } from "@edgetier/watchtower-types";
import { AxiosError } from "axios";
import { axios } from "utilities/axios";
import { setContext, setTag } from "@sentry/react";

/**
 * Request a client.
 * @param url URL to get client.
 */
const requestClient = async (url: string) => {
    const { data } = await axios.get<IClient>(url);
    setContext("client", { name: data.client });
    setTag("watchtower_client", data.client);
    return data;
};

/**
 * Get a client's details.
 * @param clientId Client identifier.
 * @returns        React Query result.
 */
export const useClient = <T = IClient>(
    clientId: string,
    options: Omit<UseQueryOptions<IClient, AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Route.Client.replace(":clientId", clientId);
    return useQuery<IClient, AxiosError, T>([url, clientId], () => requestClient(url), options);
};
