import { useTag } from "hooks-for/tags/use-tag";

/**
 * Tag info specific breadcrumb item.
 * @param props.tagId The tag id.
 * @returns Null if the tag is loading. Otherwise, the tag group and tag name.
 */
export const TagInfoBreadcrumb = ({ tagId }: { tagId: string }) => {
    const { data: tag, isLoading } = useTag(tagId);
    return isLoading ? null : <span>{tag?.tagGroup + " / " + tag?.tag}</span>;
};
